import { User } from '../../models/users';

export interface AuthState {
  userId?: string;
  profile?: User;
  token?: string;
}

export interface LoginAction {
  type: 'LOGIN';
  payload: {
    userId: string;
    token: string;
  };
}

export interface SetProfileAction {
  type: 'SET_PROFILE';
  payload: {
    profile: User;
  };
}

export interface LogoutAction {
  type: 'LOGOUT';
}

export type AuthAction = LoginAction | SetProfileAction | LogoutAction;

const reducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOGIN': {
      return {
        ...state,
        userId: action.payload.userId,
        token: action.payload.token,
      };
    }
    case 'SET_PROFILE': {
      return {
        ...state,
        profile: action.payload.profile,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        profile: undefined,
        userId: undefined,
        token: undefined,
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;

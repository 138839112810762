import { Button, Input, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

import useProfile from '../state/auth/useProfile';
import Header from '../components/Header';

const RegisterPage = () => {
  const { register, error } = useProfile();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleRegister = useCallback(async () => {
    if (await register(username, password, confirmPassword)) {
      navigate('/');
    }
  }, [register, username, password, confirmPassword, navigate]);

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <Header />

      <div className="flex flex-col items-center overflow-auto p-4">
        <form
          className="flex flex-col w-full max-w-xl p-4"
          onSubmit={(e) => {
            e.preventDefault();
            handleRegister();
          }}
        >
          <Typography.Text>Username</Typography.Text>
          <Input
            className="mb-2"
            title="Username"
            onChange={(e) => setUsername(e.target.value)}
          />
          <Typography.Text>Password</Typography.Text>
          <Input
            className="mb-2"
            type="password"
            title="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Typography.Text>Confirm Password</Typography.Text>
          <Input
            className="mb-4"
            type="password"
            title="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="primary"
            htmlType="submit"
            disabled={!username || !password || !confirmPassword}
          >
            Register
          </Button>
          {error && (
            <Typography className="mr-2 text-red-500">{error}</Typography>
          )}
        </form>
      </div>
    </div>
  );
};
export default RegisterPage;

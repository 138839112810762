import { useParams } from 'react-router-dom';
import useRanking from '../../state/ranking/useRanking';
import InsertionSortPage from './InsertionSortPage';
import { Empty } from 'antd';
import Header from '../../components/Header';

const SortSongsPage = () => {
  const { id } = useParams();
  const ranking = useRanking(id!);

  // if (unsorted.length < 15) {
  //   return <ManualSortPage />;
  // }

  const empty =
    !ranking.sorted || !ranking.unsorted || ranking.unsorted.length === 0;
  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <Header />

      {empty && <Empty description="Nothing to sort." />}
      {!empty && <InsertionSortPage ranking={ranking} />}
    </div>
  );
};
export default SortSongsPage;

import { PropsWithChildren } from 'react';
import AuthProvider from './auth';
import RankingProvider from './ranking';

const StateProvider = ({ children }: PropsWithChildren) => {
  return (
    <AuthProvider>
      <RankingProvider>{children}</RankingProvider>
    </AuthProvider>
  );
};
export default StateProvider;

export const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000'
    : 'https://api.songsort.app';

export async function post(
  url: string,
  body: any,
  token?: string,
): Promise<any> {
  let options: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  const response = await fetch(url, options);
  let json;
  try {
    json = await response.json();
  } catch {
    json = {
      data: {},
      message: '',
    };
  }

  if (!response.ok) {
    throw new Error(json.message);
  }

  return json.data;
}

export async function put(
  url: string,
  body: any,
  token?: string,
): Promise<any> {
  let options: RequestInit = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  const response = await fetch(url, options);
  let json;
  try {
    json = await response.json();
  } catch {
    json = {
      data: {},
      message: '',
    };
  }

  if (!response.ok) {
    throw new Error(json.message);
  }

  return json.data;
}

export async function get(url: string, token?: string): Promise<any> {
  let options = {};
  if (token) {
    options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  const response = await fetch(url, options);
  let json;
  try {
    json = await response.json();
  } catch {
    json = {
      data: {},
      message: '',
    };
  }

  if (!response.ok) {
    throw new Error(json.message);
  }

  return json.data;
}

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, List, Progress, Spin, Typography } from 'antd';
import { UseRanking } from '../../state/ranking/useRanking';
import SongButton from '../../components/SongButton';
import SongCard from '../../components/SongCard';

interface InsertionSortPageProps {
  ranking: UseRanking;
}
type Selection = -1 | 0 | 1;
const InsertionSortPage = ({ ranking }: InsertionSortPageProps) => {
  const {
    sorted,
    sortedError,
    unsorted,
    unsortedError,
    sort,
    save,
    saveError,
  } = ranking;
  const [low, setLow] = useState(-1);
  const [high, setHigh] = useState(-1);
  const [mid, setMid] = useState(-1);
  const [songsSorted, setSongsSorted] = useState(0);

  const [selection, setSelection] = useState<Selection>(0);
  const [playing, setPlaying] = useState<Selection>(0);
  const [mustConfirm, setMustConfirm] = useState(true);
  const [listPlaying, setListPlaying] = useState(-1);

  const error = sortedError ?? unsortedError;

  useEffect(() => {
    if (low < 0 || high < 0) {
      if (sorted!.length === 0) {
        sort(0, 0);
        setLow(0);
        setHigh(1);
      } else {
        setLow(0);
        setHigh(sorted!.length);
      }
    }
  }, [high, low, sort, sorted]);

  useEffect(() => {
    if (low < 0 || high < 0) return;
    setMid(Math.floor(low + (high - low) / 2));
  }, [high, low]);

  useEffect(() => {
    if (low < 0 || high < 0) return;
    if (low === high) {
      sort(0, low);
      setLow(0);
      setHigh(sorted!.length + 1);
      setSongsSorted((s) => s + 1);
    }
  }, [low, high, sort, sorted, save]);

  useEffect(() => {
    save();
  }, [save, songsSorted]);

  const confirm = useCallback(
    (_selection: Selection) => {
      setSelection(0);
      setPlaying(0);
      setListPlaying(-1);
      if (_selection === -1) {
        setHigh(mid);
      } else if (_selection === 1) {
        setLow(mid + 1);
      }
    },
    [mid],
  );

  const select = useCallback(
    (_selection: Selection) => {
      if (_selection === selection) {
        setSelection(0);
        return;
      } else {
        setSelection(_selection);
      }

      if (!mustConfirm) {
        confirm(_selection);
      }
    },
    [confirm, mustConfirm, selection],
  );

  return (
    <div className="flex-1 flex flex-col items-center overflow-auto">
      {error && <Typography className="mr-2 text-red-500">{error}</Typography>}
      {sorted!.length === 0 && <Spin />}
      {low >= 0 && high >= 0 && mid >= 0 && !error && sorted!.length > 0 && (
        <div className="flex-1 flex flex-col w-full max-w-xl p-4">
          <Progress
            className="mb-4 max-w-xs self-center"
            percent={(songsSorted / (unsorted!.length + songsSorted)) * 100}
            format={() => `${songsSorted} / ${unsorted!.length + songsSorted}`}
          />
          <SongButton
            song={unsorted![0]}
            selected={selection === -1}
            playing={playing === -1}
            setPlaying={(playing) => {
              if (playing) {
                setPlaying(-1);
                setListPlaying(-1);
              } else {
                setPlaying(0);
              }
            }}
            className="mb-4 overflow-hidden"
            onClick={() => select(-1)}
          />
          <SongButton
            song={sorted![mid]}
            selected={selection === 1}
            playing={playing === 1}
            setPlaying={(playing) => {
              if (playing) {
                setPlaying(1);
                setListPlaying(-1);
              } else {
                setPlaying(0);
              }
            }}
            className="mb-4 overflow-hidden"
            onClick={() => select(1)}
          />
          <Checkbox
            className="mb-4"
            checked={mustConfirm}
            onChange={(e) => setMustConfirm(e.target.checked)}
          >
            Require confirmation
          </Checkbox>
          <Button
            type="primary"
            className="mb-2"
            disabled={!mustConfirm || selection === 0}
            onClick={() => confirm(selection)}
          >
            Confirm
          </Button>
          {saveError && (
            <Typography className="mr-2 text-red-500">
              Error saving: {saveError}
            </Typography>
          )}

          {sorted && sorted.length > 0 && (
            <>
              <Typography.Title level={4} className="mt-8">
                Sorted
              </Typography.Title>
              <List itemLayout="horizontal">
                {sorted.map((song, i) => (
                  <SongCard
                    key={`song_${song.id}`}
                    song={song}
                    playing={playing === 0 && listPlaying === i}
                    setPlaying={(p) => {
                      if (p) {
                        setListPlaying(i);
                        setPlaying(0);
                      } else {
                        setListPlaying(-1);
                      }
                    }}
                  />
                ))}
              </List>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default InsertionSortPage;

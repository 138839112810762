import {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import authReducer, { AuthAction, AuthState } from './reducer';

const storedAuthState = localStorage.getItem('auth_state');
const initialAuthState: AuthState = !!storedAuthState
  ? JSON.parse(storedAuthState)
  : {};

export const AuthContext = createContext<AuthState>(initialAuthState);
export const AuthDispatchContext = createContext<Dispatch<AuthAction>>(
  () => {},
);

export const useAuthState = (): [AuthState, Dispatch<AuthAction>] => {
  const state = useContext(AuthContext);
  const dispatch = useContext(AuthDispatchContext);

  return [state, dispatch];
};

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [authState, authDispatch] = useReducer(authReducer, initialAuthState);

  useEffect(() => {
    localStorage.setItem('auth_state', JSON.stringify(authState));
  }, [authState]);

  return (
    <AuthContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={authDispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};
export default AuthProvider;

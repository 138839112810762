import { ConfigProvider } from 'antd';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import ProfilePage from './pages/ProfilePage';
import RegisterPage from './pages/RegisterPage';
import StateProvider from './state';
import SpotifyCallbackPage from './pages/SpotifyCallbackPage';
import SortSongsPage from './pages/SortSongsPage/index';
import useProfile from './state/auth/useProfile';

// random color I found which I like: #E77F6E

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/register',
    element: <RegisterPage />,
  },
  {
    path: '/profile/:id',
    element: <ProfilePage />,
  },
  {
    path: '/sort/:id',
    element: <SortSongsPage />,
  },
  {
    path: '/callback',
    element: <SpotifyCallbackPage />,
  },
]);

function Routes() {
  const { refreshToken } = useProfile();
  useInterval(refreshToken, 60 * 1000 * 10);

  return <RouterProvider router={router} />;
}

function App() {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#E77F6E' } }}>
      <StateProvider>
        <Routes />
      </StateProvider>
    </ConfigProvider>
  );
}
export default App;

import { Button, Card, Image, Typography } from 'antd';
import { Song } from '../models/songs';
import { PauseCircleFilled, PlayCircleFilled } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';

export interface SongButtonProps {
  className: string;
  selected: boolean;
  song: Song;
  onClick: () => void;
  setPlaying: (playing: boolean) => void;
  playing: boolean;
}
const SongButton = ({
  song,
  playing,
  setPlaying,
  ...props
}: SongButtonProps) => {
  const [audio, setAudio] = useState<HTMLAudioElement | undefined>();
  const onEnded = useCallback(() => setPlaying(false), [setPlaying]);

  useEffect(() => {
    if (song.preview_url) {
      setAudio(new Audio(song.preview_url));
    }
  }, [song.preview_url]);

  useEffect(() => {
    if (!audio || audio.onended) return;
    audio.onended = onEnded;

    return () => {
      audio.onended = null;
    };
  }, [audio, onEnded]);

  useEffect(() => {
    if (playing && audio) {
      audio?.play();
    } else {
      audio?.pause();
    }
  }, [playing, audio]);

  return (
    <Card
      bordered
      style={
        props.selected
          ? { borderColor: '#E77F6E', color: '#E77F6E' }
          : { border: '1px solid #ddd' }
      }
      className={`h-auto cursor-pointer ${props.className}`}
      onClick={props.onClick}
    >
      <div className="flex-1 flex">
        <Image
          src={song.albumArtUrl}
          width={64}
          height={64}
          rootClassName="self-center"
          className="rounded-md"
        />
        <div className="flex-1 flex flex-col px-4 text-left overflow-hidden whitespace-break-spaces">
          <Typography.Text className="">{song.name}</Typography.Text>
          <Typography.Text type="secondary" ellipsis className="text-xs">
            {song.artistName}
          </Typography.Text>
        </div>
        {audio && (
          <Button
            shape="circle"
            icon={playing ? <PauseCircleFilled /> : <PlayCircleFilled />}
            className="self-center"
            onClick={(e) => {
              e.stopPropagation();
              setPlaying(!playing);
              e.currentTarget.blur();
            }}
          />
        )}
      </div>
    </Card>
  );
};
export default SongButton;

import useRedirectCache from '../state/cache/useRedirectCache';

const SPOTIFY_CLIENT_ID = 'b554b737997a4161bee861dbdacc54c9';
const SPOTIFY_REDIRECT_URI =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/callback'
    : 'https://songsort.app/callback';

const ConnectSpotifyButton = () => {
  const navCache = useRedirectCache();

  return (
    <button
      className="text-spotify border border-spotify hover:text-slate-700 hover:border-slate-700 rounded-2xl py-1 px-4"
      onClick={() => {
        navCache.saveCurrentUrl();
        window.location.href = `https://accounts.spotify.com/authorize?client_id=${SPOTIFY_CLIENT_ID}&response_type=code&redirect_uri=${SPOTIFY_REDIRECT_URI}&scope=user-library-read`;
      }}
    >
      Connect Spotify
    </button>
  );
};
export default ConnectSpotifyButton;

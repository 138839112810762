import { LogoutOutlined, MehOutlined } from '@ant-design/icons';
import { Button, Dropdown, Image, MenuProps, Tag, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import { useNavigate } from 'react-router';

import useProfile from '../state/auth/useProfile';

const Header = () => {
  const { profile, error, logout } = useProfile();
  const navigate = useNavigate();

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      label: 'Profile',
      icon: <MehOutlined />,

      onClick: () => navigate(`/profile/${profile?.guid}`),
    },
    {
      key: 'logout',
      label: 'Logout',
      icon: <LogoutOutlined />,
      danger: true,
      onClick: logout,
    },
  ];

  return (
    <div
      style={{ borderBottom: '2px solid #ddd' }}
      className="flex border-b-2 p-4 justify-between items-center"
    >
      <div className="flex items-center">
        <a href="/" className="flex items-center">
          <Image width={50} height={50} src="/logo512.png" preview={false} />
          <Title
            level={2}
            className="ml-2 maxsm:hidden"
            style={{ marginBottom: 0 }}
          >
            Song Ranker
          </Title>
          <Tag className="ml-2" color="#E77F6E">
            Alpha
          </Tag>
        </a>
      </div>

      <div className="flex items-center">
        {profile?.guid && (
          <div>
            <Dropdown arrow menu={{ items }} className="cursor-pointer">
              <Typography
                className="mr-2"
                onClick={() => navigate(`/profile/${profile?.guid}`)}
              >
                {profile?.username}
              </Typography>
            </Dropdown>
          </div>
        )}
        {!profile?.guid && !error && (
          <div className="flex">
            <Button type="link" onClick={() => navigate('/register')}>
              Register
            </Button>
            <Button
              type="primary"
              className="ml-2"
              onClick={() => navigate('/login')}
            >
              Login
            </Button>
          </div>
        )}
        {!profile && error && (
          <Typography className="mr-2 text-red-500">{error}</Typography>
        )}
      </div>
    </div>
  );
};
export default Header;

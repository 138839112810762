import { useCallback, useState } from 'react';
import { Ranking } from '../../models/rankings';
import { useRankingState } from '.';
import {
  getRanking,
  getTracks,
  getUnsorted,
  saveRanking,
} from '../../api/ranking';
import { useAuthState } from '../auth';
import { Song } from '../../models/songs';

export interface UseRanking {
  ranking: Ranking | undefined;
  rankingLoading: boolean;
  rankingError: string;

  sorted: Song[] | undefined;
  sortedLoading: boolean;
  sortedError: string;

  unsorted: Song[] | undefined;
  unsortedLoading: boolean;
  unsortedError: string;

  saveLoading: boolean;
  saveError: string;

  refresh: () => void;
  sort: (src: number, dst: number) => void;
  save: () => void;
}
const useRanking = (name: string): UseRanking => {
  const [state, dispatch] = useRankingState();
  const [authState] = useAuthState();

  const [rankingLoading, setRankingLoading] = useState(false);
  const [rankingError, setRankingError] = useState('');

  const [sortedLoading, setSortedLoading] = useState(false);
  const [sortedError, setSortedError] = useState('');

  const [unsortedLoading, setUnsortedLoading] = useState(false);
  const [unsortedError, setUnsortedError] = useState('');

  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState('');

  return {
    ranking: state.rankings[name]?.ranking,
    rankingLoading,
    rankingError,

    sorted: state.rankings[name]?.sorted,
    sortedLoading,
    sortedError,

    unsorted: state.rankings[name]?.unsorted,
    unsortedLoading,
    unsortedError,

    saveLoading,
    saveError,

    refresh: useCallback(async () => {
      if (!authState.token) {
        setRankingError('Not logged in.');
        return;
      }

      // TODO: parallelize
      let ranking: Ranking;
      try {
        setRankingError('');
        setRankingLoading(true);

        const { ranking: _ranking } = await getRanking(name, authState.token);
        ranking = _ranking;
      } catch (err: any) {
        setRankingError(err.message);
        setRankingLoading(false);
        return;
      }
      setRankingLoading(false);

      let unsorted: Song[];
      try {
        setUnsortedError('');
        setUnsortedLoading(true);

        const { songs } = await getUnsorted(name, authState.token);
        unsorted = songs;
      } catch (err: any) {
        setUnsortedError(err.message);
        setUnsortedLoading(false);
        return;
      }
      setUnsortedLoading(false);

      let sorted: Song[];
      try {
        setSortedError('');
        setSortedLoading(true);

        const { songs } = await getTracks(ranking.trackIds, authState.token);
        sorted = songs;
      } catch (err: any) {
        setSortedError(err.message);
        setSortedLoading(false);
        return;
      }
      setSortedLoading(false);

      dispatch({
        type: 'SET_RANKING',
        payload: { name, ranking, sorted, unsorted },
      });
      setRankingLoading(false);
      setUnsortedLoading(false);
    }, [authState.token, dispatch, name]),

    sort: useCallback(
      (srcIndex: number, dstIndex: number) => {
        setRankingError('');
        setSortedError('');
        setUnsortedError('');

        if (!state.rankings[name]) {
          setRankingError("Ranking doesn't exist.");
          return;
        }
        if (state.rankings[name].unsorted.length <= srcIndex) {
          setUnsortedError('Invalid sort index.');
          return;
        }
        if (state.rankings[name].sorted.length < dstIndex) {
          setSortedError('Invalid sort index.');
          return;
        }

        const _sorted = [...state.rankings[name].sorted];
        const _unsorted = [...state.rankings[name].unsorted];

        const [track] = _unsorted.splice(srcIndex, 1);
        _sorted.splice(dstIndex, 0, track);
        const trackIds = _sorted.map((t) => t.id);

        dispatch({
          type: 'SET_RANKING',
          payload: {
            name,
            sorted: _sorted,
            unsorted: _unsorted,
            ranking: {
              ...state.rankings[name].ranking,
              trackIds,
            },
          },
        });
      },
      [dispatch, name, state.rankings],
    ),

    save: useCallback(async () => {
      if (!authState.token) {
        setSaveError('Not logged in.');
        return;
      }
      if (!state.rankings[name]) {
        setSaveError("Ranking doesn't exist");
        return;
      }

      setSaveError('');
      setSaveLoading(true);

      try {
        await saveRanking(name, state.rankings[name].ranking, authState.token);
      } catch (err: any) {
        setSaveError(err.message);
      }

      setSaveLoading(false);
    }, [authState.token, name, state.rankings]),
  };
};
export default useRanking;

import { BASE_URL, get, put } from '.';
import { Ranking } from '../models/rankings';
import { Song } from '../models/songs';

export interface GetRankingResponse {
  ranking: Ranking;
}

export interface GetUnsortedSongsResponse {
  rankingName: string;
  songs: Song[];
  count: number;
}

export interface GetSongsResponse {
  songs: Song[];
  count: number;
}

export async function getRanking(
  name: string,
  token: string,
): Promise<GetRankingResponse> {
  return get(`${BASE_URL}/rankings/${name}`, token);
}

export async function saveRanking(
  name: string,
  ranking: Ranking,
  token: string,
): Promise<void> {
  return put(
    `${BASE_URL}/rankings/${name}`,
    { name, trackIds: ranking.trackIds },
    token,
  );
}

export async function getUnsorted(
  name: string,
  token: string,
): Promise<GetUnsortedSongsResponse> {
  return get(`${BASE_URL}/rankings/${name}/unsorted`, token);
}

export async function getTracks(
  trackIds: string[],
  token: string,
): Promise<GetSongsResponse> {
  if (trackIds.length === 0) {
    return {
      songs: [],
      count: 0,
    };
  }
  const query = trackIds.join(',');
  return get(`${BASE_URL}/rankings/tracks?ids=${query}`, token);
}

import { Button, Card, Empty, List, Spin, Typography } from 'antd';
import Header from '../components/Header';
import useProfile from '../state/auth/useProfile';
import useRanking from '../state/ranking/useRanking';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import SongCard from '../components/SongCard';
import ConnectSpotifyButton from '../components/ConnectSpotifyButton';

const HomePage = () => {
  const navigate = useNavigate();
  const { profile, loading, refreshProfile } = useProfile();
  const {
    ranking,
    rankingLoading,
    sorted,
    unsorted,
    unsortedLoading,
    refresh,
  } = useRanking('favorite');
  const [playing, setPlaying] = useState(-1);

  useEffect(() => {
    if (!ranking && profile?.spotifyId) {
      refresh();
    }
  }, [profile?.spotifyId, ranking, refresh]);

  useEffect(() => {
    if (!profile?.spotifyId) {
      refreshProfile();
    }
  }, [profile?.spotifyId, refreshProfile]);

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <Header />

      <div className="flex-1 flex flex-col items-center overflow-auto">
        <div className="flex-1 flex flex-col w-full max-w-5xl py-8 px-4">
          {!profile?.guid && <Empty description="Not logged in" />}
          {(loading || rankingLoading || unsortedLoading) && (
            <Spin size="large" />
          )}

          {unsorted && unsorted.length > 0 && (
            <Card className="mb-4">
              <Typography.Text>
                Found {unsorted.length} unsorted songs.
              </Typography.Text>
              <Button type="link" onClick={() => navigate('/sort/favorite')}>
                Sort
              </Button>
            </Card>
          )}

          {profile && !profile.spotifyId && (
            <Card className="mb-4">
              <Typography.Text className="mr-2">
                You must connect your spotify account.
              </Typography.Text>
              <ConnectSpotifyButton />
            </Card>
          )}

          {sorted && sorted.length > 0 && (
            <List itemLayout="horizontal">
              {sorted.map((track, i) => (
                <SongCard
                  key={`song_${i}`}
                  song={track}
                  playing={playing === i}
                  setPlaying={(p) => {
                    if (p) {
                      setPlaying(i);
                    } else {
                      setPlaying(-1);
                    }
                  }}
                />
              ))}
            </List>
          )}
        </div>
      </div>
    </div>
  );
};
export default HomePage;

import { BASE_URL, get, post } from '.';
import { User } from '../models/users';

export interface AuthenticatedResult {
  token: string;
  profile?: User;
}

export async function login(
  username: string,
  password: string,
): Promise<AuthenticatedResult> {
  const { accessToken } = await post(`${BASE_URL}/auth/login`, {
    username,
    password,
  });

  return {
    profile: await getCurrentProfile(accessToken),
    token: accessToken,
  };
}

export async function refreshToken(token: string) {
  const { accessToken } = await get(`${BASE_URL}/auth/refresh-token`, token);

  return {
    token: accessToken,
  };
}

export async function register(
  username: string,
  password: string,
): Promise<AuthenticatedResult> {
  const { accessToken } = await post(`${BASE_URL}/auth/register`, {
    username,
    password,
  });

  return {
    profile: await getCurrentProfile(accessToken),
    token: accessToken,
  };
}

export async function getCurrentProfile(token: string): Promise<User> {
  return get(`${BASE_URL}/users/me`, token);
}

export async function linkSpotify(spotifyToken: string, token: string) {
  await post(`${BASE_URL}/auth/link-spotify`, { spotifyToken }, token);
}

import { Button, Input, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

import Header from '../components/Header';
import useProfile from '../state/auth/useProfile';

const LoginPage = () => {
  const { login, error } = useProfile();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = useCallback(async () => {
    if (await login(username, password)) {
      navigate('/');
    }
  }, [login, username, password, navigate]);

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <Header />

      <div className="flex-1 flex flex-col items-center overflow-auto p-4">
        <form
          className="flex flex-col w-full max-w-xl p4"
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <Typography.Text>Username</Typography.Text>
          <Input
            className="mb-2"
            title="Username"
            onChange={(e) => setUsername(e.target.value)}
          />
          <Typography.Text>Password</Typography.Text>
          <Input
            className="mb-2"
            type="password"
            title="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="primary"
            htmlType="submit"
            disabled={!username || !password}
          >
            Login
          </Button>
          {error && (
            <Typography className="mr-2 text-red-500">{error}</Typography>
          )}
        </form>
      </div>
    </div>
  );
};
export default LoginPage;

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Spin, Typography } from 'antd';

import useLinkSpotify from '../state/auth/useLinkSpotify';
import useRedirectCache from '../state/cache/useRedirectCache';
import Header from '../components/Header';

const SpotifyCallbackPage = () => {
  const { redirect } = useRedirectCache();
  const { success, loading, error, link } = useLinkSpotify();
  const [params] = useSearchParams();
  const code = params.get('code');

  useEffect(() => {
    if (!code) return;

    if (success) {
      redirect();
      return;
    }

    link(code);
  }, [code, link, redirect, success]);

  return (
    <div>
      <Header />
      <div className="flex flex-col justify-center">
        {!code && (
          <Typography className="mr-2 text-red-500">
            Missing Spotify Code.
          </Typography>
        )}
        {code && !error && (
          <Typography>Connecting your spotify account...</Typography>
        )}
        {error && (
          <Typography className="mr-2 text-red-500">{error}</Typography>
        )}
        {code && loading && !error && <Spin />}
      </div>
    </div>
  );
};
export default SpotifyCallbackPage;

import { Button, Image, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import { useNavigate, useParams } from 'react-router';

import Header from '../components/Header';
import ConnectSpotifyButton from '../components/ConnectSpotifyButton';
import useProfile from '../state/auth/useProfile';
import useRanking from '../state/ranking/useRanking';
import { useEffect } from 'react';

const ProfilePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { profile, refreshProfile } = useProfile();
  const {
    ranking,
    rankingLoading,
    rankingError,
    sorted,
    sortedError,
    unsorted,
    unsortedLoading,
    unsortedError,
    refresh,
  } = useRanking('favorite');
  const error = rankingError ?? sortedError ?? unsortedError ?? '';

  useEffect(() => {
    if (!ranking) {
      refresh();
    }
  }, [ranking, refresh]);

  useEffect(() => {
    refreshProfile();
  }, [refreshProfile]);

  return (
    <div>
      <Header />
      {profile && profile.guid === id ? (
        <div className="px-16 py-8 flex flex-col">
          <div className="flex">
            <Image
              width={250}
              src={profile.profileImageUri}
              fallback="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
              height={256}
            />
            <div className="flex flex-col ml-4 py-4">
              <Title level={2}>{profile.username}</Title>
              {profile.spotifyId && (
                <>
                  {profile.displayName && (
                    <Typography.Text>
                      Spotify Name: {profile.displayName}
                    </Typography.Text>
                  )}
                  {profile.spotifyId && (
                    <Typography.Text>
                      Spotify ID: {profile.spotifyId}
                    </Typography.Text>
                  )}
                  <Typography.Text disabled={rankingLoading || unsortedLoading}>
                    Songs Sorted: {sorted?.length ?? 0}
                  </Typography.Text>
                  <Typography.Text disabled={rankingLoading || unsortedLoading}>
                    Songs Unsorted: {unsorted?.length ?? 0}
                  </Typography.Text>
                  <div className="flex">
                    {unsorted && unsorted.length > 0 && (
                      <Button
                        type="link"
                        onClick={() => navigate('/sort/favorite')}
                        disabled={rankingLoading || unsortedLoading}
                      >
                        Sort
                      </Button>
                    )}
                    <Button
                      type="link"
                      onClick={refresh}
                      loading={rankingLoading || unsortedLoading}
                      disabled={rankingLoading || unsortedLoading}
                    >
                      Refresh
                    </Button>
                  </div>
                  {error && (
                    <Typography className="mr-2 text-red-500">
                      {error}
                    </Typography>
                  )}
                </>
              )}
              {!profile.spotifyId && <ConnectSpotifyButton />}
            </div>
          </div>
        </div>
      ) : (
        <h1>Not Implemented</h1>
      )}
    </div>
  );
};
export default ProfilePage;

import { useCallback, useState } from 'react';

import { useAuthState } from '.';
import {
  getCurrentProfile,
  login,
  refreshToken,
  register,
} from '../../api/auth';
import { User } from '../../models/users';
import { useRankingState } from '../ranking';

const useProfile = (): {
  profile?: User;
  loading: boolean;
  error?: string;

  login: (u: string, p: string) => Promise<boolean>;
  register: (u: string, p: string, cp: string) => Promise<boolean>;
  logout: () => void;
  refreshProfile: () => void;
  refreshToken: () => void;
} => {
  const [state, dispatch] = useAuthState();
  const [, rankingDispatch] = useRankingState();
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  return {
    profile: state.profile,
    loading,
    error,

    login: useCallback(
      async (username: string, password: string) => {
        setLoading(true);
        setError(undefined);

        try {
          const { profile, token } = await login(username, password);
          if (!profile) {
            throw new Error('Profile not found');
          }

          dispatch({ type: 'LOGIN', payload: { token, userId: profile.guid } });
          dispatch({ type: 'SET_PROFILE', payload: { profile } });
          setLoading(false);

          return true;
        } catch (err: any) {
          setError(err.message);
          setLoading(false);
        }

        return false;
      },
      [dispatch],
    ),

    register: useCallback(
      async (username: string, password: string, confirmPassword: string) => {
        if (password !== confirmPassword) {
          setError('Passwords do not match.');
          return false;
        }

        setLoading(true);
        setError(undefined);

        try {
          const { profile, token } = await register(username, password);
          if (!profile) {
            throw new Error('Failed to create profile');
          }

          dispatch({ type: 'LOGIN', payload: { token, userId: profile.guid } });
          dispatch({ type: 'SET_PROFILE', payload: { profile } });
          setLoading(false);

          return true;
        } catch (err: any) {
          setError(err.message);
          setLoading(false);
        }

        return false;
      },
      [dispatch],
    ),

    logout: useCallback(async () => {
      setError(undefined);
      rankingDispatch({ type: 'CLEAR_RANKINGS', payload: {} });
      dispatch({ type: 'LOGOUT' });
      window.location.href = '/login';
    }, [dispatch, rankingDispatch]),

    refreshProfile: useCallback(async () => {
      if (!state.token) {
        setError('Not logged in');
        return;
      }

      setLoading(true);
      setError(undefined);

      try {
        const profile = await getCurrentProfile(state.token);
        dispatch({ type: 'SET_PROFILE', payload: { profile } });
        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    }, [dispatch, state.token]),

    refreshToken: useCallback(async () => {
      if (!state.token || !state.profile?.guid) {
        return;
      }

      try {
        const { token } = await refreshToken(state.token);
        dispatch({
          type: 'LOGIN',
          payload: {
            userId: state.profile.guid,
            token,
          },
        });
      } catch (err: any) {
        rankingDispatch({ type: 'CLEAR_RANKINGS', payload: {} });
        dispatch({ type: 'LOGOUT' });
      }
    }, [dispatch, rankingDispatch, state.profile, state.token]),
  };
};
export default useProfile;

import { useNavigate } from 'react-router';
import useCache from './useCache';
import { useCallback } from 'react';

const useRedirectCache = (): {
  url: string;
  saveCurrentUrl: () => void;
  saveUrl: (url: string) => void;
  redirect: () => void;
} => {
  const cache = useCache('redirect_url_cache');

  return {
    url: cache.value,

    saveCurrentUrl: useCallback(() => {
      cache.set(window.location.href);
    }, [cache]),

    saveUrl: useCallback(
      (url: string) => {
        cache.set(url);
      },
      [cache],
    ),

    redirect: useCallback(() => {
      window.location.href = cache.value;
    }, [cache.value]),
  };
};
export default useRedirectCache;

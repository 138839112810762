import { useCallback, useState } from 'react';
import { linkSpotify } from '../../api/auth';
import { useAuthState } from '.';

const useLinkSpotify = (): {
  success: boolean;
  loading: boolean;
  error: string;
  link: (code: string) => void;
} => {
  const [{ token }] = useAuthState();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  return {
    success,
    loading,
    error,
    link: useCallback(
      (code: string) => {
        if (!token) {
          setError('Not logged in.');
          return;
        }

        setLoading(true);
        setError('');

        linkSpotify(code, token)
          .then(() => {
            setLoading(false);
            setSuccess(true);
          })
          .catch((err: any) => {
            setLoading(false);
            setError(err.message);
          });
      },
      [token],
    ),
  };
};
export default useLinkSpotify;

import { useCallback, useEffect, useState } from 'react';

const useCache = (
  key: string,
): {
  value: any | undefined;

  set: (value: any) => void;
  clear: () => void;
} => {
  const [value, setValue] = useState<any | undefined>();

  useEffect(() => {
    setValue(localStorage.getItem(key));
  }, [key]);

  return {
    value,

    set: useCallback(
      (value) => {
        localStorage.setItem(key, value);
        setValue(value);
      },
      [key],
    ),
    clear: useCallback(() => {
      localStorage.removeItem(key);
      setValue(undefined);
    }, [key]),
  };
};
export default useCache;

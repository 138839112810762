import {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import rankingReducer, { RankingAction, RankingState } from './reducer';

const storedRankingState = localStorage.getItem('ranking_state');
const initialRankingState: RankingState = !!storedRankingState
  ? JSON.parse(storedRankingState)
  : {
      rankings: {},
    };

export const RankingContext = createContext<RankingState>(initialRankingState);
export const RankingDispatchContext = createContext<Dispatch<RankingAction>>(
  () => {},
);

export const useRankingState = (): [RankingState, Dispatch<RankingAction>] => {
  const state = useContext(RankingContext);
  const dispatch = useContext(RankingDispatchContext);

  return [state, dispatch];
};

const RankingProvider = ({ children }: PropsWithChildren) => {
  const [rankingState, rankingDispatch] = useReducer(
    rankingReducer,
    initialRankingState,
  );

  useEffect(() => {
    localStorage.setItem('ranking_state', JSON.stringify(rankingState));
  }, [rankingState]);

  return (
    <RankingContext.Provider value={rankingState}>
      <RankingDispatchContext.Provider value={rankingDispatch}>
        {children}
      </RankingDispatchContext.Provider>
    </RankingContext.Provider>
  );
};
export default RankingProvider;

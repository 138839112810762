import { Ranking } from '../../models/rankings';
import { Song } from '../../models/songs';

export interface RankingState {
  rankings: {
    [name: string]: {
      ranking: Ranking;
      unsorted: Song[];
      sorted: Song[];
    };
  };
}

export interface SetRankingAction {
  type: 'SET_RANKING';
  payload: {
    name: string;
    ranking: Ranking;
    sorted?: Song[];
    unsorted?: Song[];
  };
}

export interface SetSortedAction {
  type: 'SET_SORTED';
  payload: {
    name: string;
    sorted: Song[];
  };
}

export interface SetUnsortedAction {
  type: 'SET_UNSORTED';
  payload: {
    name: string;
    unsorted: Song[];
  };
}

export interface ClearRankingsAction {
  type: 'CLEAR_RANKINGS';
  payload: {};
}

export type RankingAction =
  | SetRankingAction
  | SetSortedAction
  | SetUnsortedAction
  | ClearRankingsAction;

const reducer = (
  state: RankingState,
  { type, payload }: RankingAction,
): RankingState => {
  switch (type) {
    case 'SET_RANKING': {
      return {
        ...state,
        rankings: {
          ...state.rankings,
          [payload.name]: {
            ...state.rankings[payload.name],
            ranking: payload.ranking,
            sorted: payload.sorted ?? [],
            unsorted: payload.unsorted ?? [],
          },
        },
      };
    }
    case 'SET_SORTED': {
      return {
        ...state,
        rankings: {
          ...state.rankings,
          [payload.name]: {
            ...state.rankings[payload.name],
            sorted: payload.sorted,
          },
        },
      };
    }
    case 'SET_UNSORTED': {
      return {
        ...state,
        rankings: {
          ...state.rankings,
          [payload.name]: {
            ...state.rankings[payload.name],
            unsorted: payload.unsorted,
          },
        },
      };
    }
    case 'CLEAR_RANKINGS': {
      return {
        ...state,
        rankings: {},
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
